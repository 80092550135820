import React, { useRef, useEffect } from 'react';
import cls from 'classnames';

import { Container } from '@ott/grid';
import { useLayoutContext } from '@ott/layout-context';
import usePreviousValue from '../../../hooks/usePreviousValue';
import SimpleSlider from '../SimpleSlider';

import styles from './SeoLinks.scss';

type Props = {
  className: string;
  title?: string;
  columns: Array<{
    title: string;
    links: Array<{
      title: string;
      href: string;
    }>;
  }>;
  config: { product: string; };
  webView: { isWebView: boolean; };
};

type RefType = {
  slickGoTo: (index: number) => void;
};

const SeoLinks = (props: Props) => {
  const {
    className,
    title,
    columns,
    config: { product },
    webView: { isWebView },
  } = props;
  const { isDesktop } = useLayoutContext();
  const prevProduct = usePreviousValue(product);
  const sliderRef = useRef<RefType | null>(null);

  const responsive = [
    {
      breakpoint: 1132,
      settings: { slidesToShow: 2 },
    },
    {
      breakpoint: 1024,
      settings: { slidesToShow: 1 },
    },
    {
      breakpoint: 768,
      settings: { slidesToShow: 1 },
    },
    {
      breakpoint: 460,
      settings: { slidesToShow: 1 },
    },
  ];

  useEffect(() => {
    if (product !== prevProduct) {
      if (sliderRef.current) {
        sliderRef.current.slickGoTo(0);
      }
    }
  }, [product, prevProduct]);

  const renderColumns = () => {
    let columnTitle = '';

    return columns.map((column, columnIndex) => {
      if (column.title) {
        columnTitle = column.title;
      }

      return (
        <div className={styles.column} key={columnIndex} data-locator="seo-links column">
          <h3
            className={styles.subtitle}
            data-locator="seo-links column-title"
            dangerouslySetInnerHTML={{ __html: columnTitle }}
          />
          <div
            className={cls(styles.list, {
              [styles.singleList]: columns.length === 1,
            })}
            data-locator="seo-links column-links"
          >
            {column.links.map((link, linkIndex) => (
              <a key={linkIndex} className={styles.link} href={link.href}>
                {link.title}
                <img
                  className={styles.arrow}
                  src="https://static.onetwotrip.com/images/index/arrow_right_icon.svg"
                  alt="arrow-icon"
                />
              </a>
            ))}
          </div>
        </div>
      );
    });
  };

  if (isWebView && product === 'activities') {
    return null;
  }

  return (
    <Container data-locator="seo-links" className={className}>
      {title ? <h1 className={styles.title}>{title}</h1> : null}
      {columns.length === 1 ? (
        <>
          {renderColumns()}
        </>
      ) : (
        <SimpleSlider
          infinite={false}
          slidesToShow={2}
          swipeToSlide={true}
          responsive={responsive}
          dots={!isDesktop}
          arrows={isDesktop}
          className={styles.slider}
          // eslint-disable-next-line no-return-assign
          setRef={(ref: RefType) => (sliderRef.current = ref)}
        >
          {renderColumns()}
        </SimpleSlider>
      )}
    </Container>
  );
};

export default SeoLinks;

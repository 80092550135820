import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cls from 'classnames/bind';

import Header from '@ott/header';
import HeaderPartner from '@ott/header-partner';
import OTTLogoWithText from '@ott/icon-ott-logo-with-text';
import HeaderAuth, { POSITION, MENU_ITEMS, headerAuthHelpers } from '@ott/header-auth';
import addScpParameterToUrl from '@ott/utility-scp-logic';

import HotelsBadge from 'src/components/organisms/HotelsBadge';
import BadgeNewMobileIcon from 'src/components/icons/BadgeNewMobileIcon';

import { authActions } from '@ott/auth';
import { showAuth, hideAuth } from 'src/redux/modules/common/authModal/actions';
import { isOttBrand } from 'src/helpers/ottBrand';

import { lang } from '@ott/l10n';
import get from 'lodash/get';

import { withLayoutContext } from '@ott/layout-context';

import styles from './MobileIndexHeader.scss';

const OTT_HOSTNAME = 'https://www.onetwotrip.com';

const PAGE_404 = '404';

@withLayoutContext
@connect()
class MobileIndexHeader extends React.PureComponent {
  static propTypes = {
    config: PropTypes.shape(),
    className: PropTypes.string,
  };
  static defaultProps = {};

  constructor(props, ctx) {
    super(props, ctx);
  }

  isPage404() {
    const { config } = this.props;

    return config.product === PAGE_404;
  }

  getProducts() {
    const { config } = this.props;

    if (!config.navigation) {
      return [];
    }

    if (!config.navigation.mobileMenu) {
      return [];
    }

    const { products = [] } = config.navigation.mobileMenu;

    return products;
  }

  renderIconsMenuItem(item, index) {
    const { icon, link, isNew, withTripcoins } = item;

    if (!link) {
      return null;
    }

    return (
      <a
        href={addScpParameterToUrl(link.href)}
        className={styles.productLink}
        key={index}
        data-locator="productLink"
      >
        <div
          className={styles.icon}
          style={{
            backgroundImage: `url(${icon})`,
          }}
        >
          {withTripcoins && (
            <HotelsBadge className={cls(styles.iconBadge, styles.hotelsBadge)} large />
          )}
          {isNew && (
            <div className={styles.iconBadge}>
              <BadgeNewMobileIcon />
            </div>
          )}
        </div>
        <div className={styles.title}>{link.title}</div>
      </a>
    );
  }

  renderIconsMenu() {
    const { config } = this.props;

    if (this.isPage404()) {
      return null;
    }

    const products = this.getProducts();

    if (products.length <= 1) {
      return null;
    }

    if (get(config, 'navigation.mobileMenu.hideOnMobile')) {
      return null;
    }

    const links = [...products].reverse().map(this.renderIconsMenuItem);

    return (
      <div
        className={cls(styles.productLinksContainer, {
          [styles[`productLinksContainer--${products.length}items`]]: true,
        })}
        data-locator="mobile-menu"
      >
        {links}
      </div>
    );
  }

  renderBurgerMenu() {
    const { config } = this.props;

    if (this.isPage404()) {
      return null;
    }

    const authNode = !config.partner && this.renderHeaderAuth();
    const hostName = config.partner ? config.partner.hostname : OTT_HOSTNAME;
    const logoNode = isOttBrand() ? null : <OTTLogoWithText project="index" to="/" />;

    if (get(config, 'navigation.hideBurgerMenuOnMobile')) {
      return null;
    }

    return (
      <Header
        className={styles.header}
        auth={authNode}
        hostname={hostName}
        lang={lang}
        logo={logoNode}
        partner={config.partner}
      />
    );
  }

  renderPartnerHeader() {
    const { config } = this.props;

    if (!config.partner) {
      return null;
    }

    const headerAuthNode = this.renderHeaderAuth(true);
    return (
      <HeaderPartner
        partner={{
          ...config.partner,
          logo: config.partner?.logo?.mobile,
        }}
        headerAuthNode={headerAuthNode}
      />
    );
  }

  renderHeaderAuth = (inHeaderPartner = false) => {
    const { auth, config, authModal, dispatch, isMobile } = this.props;

    const { exit, regReset, getBasicUserInfo } = authActions;

    const headerAuthActions = bindActionCreators(
      {
        exit,
        regReset,
        showAuth,
        hideAuth,
        getUserInfo: getBasicUserInfo,
      },
      dispatch
    );

    const headerAuthItems = headerAuthHelpers.getIndexDropdownMenuItemsConfig();
    const menuItems = headerAuthHelpers.getIndexMenuItemsConfig();
    menuItems.notAuthorized.desktop = [MENU_ITEMS.login];

    const authHeaderSpecificLocators = {
      menuItems: {
        [MENU_ITEMS.profileLogin]: 'inactive-profileLogin',
      },
      authLabelAuthorized: 'mobileAuthLabelAuthorized',
      authLabelUnauthorized: 'mobileAuthLabelUnauthorized',
    };

    return (
      <HeaderAuth
        auth={auth}
        authModal={authModal}
        partner={config.partner}
        position={POSITION.inplace}
        authActions={{ ...headerAuthActions }}
        shouldRefetchUserInfo={false}
        dropdownMenuItemsConfig={headerAuthItems}
        menuItemsConfig={menuItems}
        useHeaderPartnerStyles={inHeaderPartner}
        useComponentAuth={isMobile}
        specificLocators={authHeaderSpecificLocators}
        className={styles.headerAuth}
      />
    );
  };

  render() {
    const { className } = this.props;

    const iconsMenuNode = this.renderIconsMenu();
    const burgerMenuNode = this.renderBurgerMenu();
    const headerPartnerNode = this.renderPartnerHeader();

    return (
      <div
        className={cls(styles.MobileIndexHeader, className)}
        data-locator="header-and-navigation-block-mobile"
      >
        {headerPartnerNode}
        {burgerMenuNode}
        {iconsMenuNode}
      </div>
    );
  }
}

export default MobileIndexHeader;

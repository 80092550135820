import Loadable from '@react-loadable/revised';
import cls from 'classnames';
import Cookie from 'js-cookie';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import qs from 'qs';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ErrorBoundary from '@ott/error-boundary';
import ga, { initGoogleAnalytics, ONETWOTRIP_COUNTER } from '@ott/google-analytics';
import { helpers as languageSwitcherHelpers } from '@ott/language-switcher';
import { envEasterEgg } from '@ott/utility-env-easter-egg';

import Empty from 'src/components/base/Empty';

import componentsMap from 'src/components/componentsMap';

import customPopups from 'src/components/organisms/customPopups';
import DownloadAppBanner from 'src/components/organisms/DownloadAppBanner';
import IndexFooter from 'src/components/organisms/IndexFooter';
import IndexHeader from 'src/components/organisms/IndexHeader';
import MobileIndexHeader from 'src/components/organisms/MobileIndexHeader';

import { closePopup, openPopup } from 'redux/modules/common/customPopup/actions';
import { setWebView } from 'redux/modules/common/viewport/actions';
import indexPageSelector from 'redux/selectors/indexPageSelector';

import metrics from 'utility/metrics';

import styles from './IndexPage.scss';

const SHOW_NOT_PRIORITY_BLOCKS_TIMEOUT = 4000;

const AsyncReservationBanner = Loadable({
  loader: () =>
    import(
      '@ott/reservation-banner/src/ReservationBanner' /* webpackChunkName: "ReservationBanner" */
    ),
  loading: Empty,
});

const AsyncLanguageSwitcher = Loadable({
  loader: () => import('@ott/language-switcher' /* webpackChunkName: "LanguageSwitcher" */),
  loading: Empty,
});

const AsyncCookiePolicy = Loadable({
  loader: () =>
    import('@ott/cookie-policy' /* webpackChunkName: "CookiePolicy" */).then(
      ({ CookiePolicy }) => CookiePolicy
    ),
  loading: Empty,
});

@connect((state, props) => {
  return {
    ...indexPageSelector(state, props),
  };
})
class IndexPage extends React.PureComponent {
  static propTypes = {
    location: PropTypes.shape().isRequired,
    product: PropTypes.string.isRequired,
    config: PropTypes.shape().isRequired,
    pageInfo: PropTypes.shape({
      deviceInfo: PropTypes.shape({
        isDesktop: PropTypes.bool,
        isMobile: PropTypes.bool,
        isTablet: PropTypes.bool,
        isTouchDevice: PropTypes.bool,
      }),
    }),
  };

  static defaultProps = {};

  constructor(props, ctx) {
    super(props, ctx);

    this.state = {
      showCookiePolicy: false,
      showDownloadAppBanner: false,
    };

    this.getUrlForSwitchLocale = this.getUrlForSwitchLocale.bind(this);
  }

  componentDidMount() {
    metrics('index_show_page');

    setTimeout(() => {
      this.setState({
        ...this.state,
        showCookiePolicy: true,
        // @TODO REF-45 (временно скрываем баннер, настроен appsflyer)
        // showDownloadAppBanner: true,
      });
    }, SHOW_NOT_PRIORITY_BLOCKS_TIMEOUT);

    envEasterEgg();

    this.initGa();
    this.startQueryParamLogic();
  }

  componentWillReceiveProps(nextProps) {
    const { product } = this.props;

    if (product !== nextProps.product) {
      metrics('index_show_page', {
        page: `index_${nextProps.product}`,
      });
    }
  }

  startQueryParamLogic() {
    const {
      dispatch,
      location: { search },
      product,
    } = this.props;

    const queryParams = qs.parse(search.slice(1));

    const { testPg, view, from, to, email, fromDate, deal, vid, id, key, gratitude } =
      queryParams || {};

    if (testPg) {
      window.localStorage.setItem(`OTT/${product}/testPg`, testPg);
    }

    if (view === 'app') {
      dispatch(
        setWebView({
          isWebView: true,
          device: Cookie.get('device'),
        })
      );
    }

    if ((from && to && fromDate && email && !deal) || (vid && id && email)) {
      dispatch(
        openPopup({
          name: 'AsyncUnsubscribeModal',
          subscriptionKey: key,
          ...queryParams,
        })
      );
    }

    if (gratitude) {
      dispatch(
        openPopup({
          name: 'AsyncGratitudeModal',
          gratitude,
        })
      );
    }
  }

  initGa() {
    const {
      config: { partner },
    } = this.props;

    initGoogleAnalytics({
      counterId: (partner && partner.gaCounterNumber) || ONETWOTRIP_COUNTER,
    })
      .then(() => {
        ga('send', 'pageview');
      })
      .catch((err) => {
        console.error(err);
      });
  }

  getUrlForSwitchLocale(language) {
    const {
      match: { params },
    } = this.props;

    let url = `/set-locale/${language}/`;

    if (params.product) {
      url += `${params.product}/`;
    }

    return url;
  }

  getLanguageSwitcher(props = {}) {
    const { config: { partner } = {} } = this.props;
    const viewLocales = [
      'ru',
      'ru-kz',
      'kz',
      'ru-by',
      'en-gb',
      'en-ie',
      'en-us',
      'es',
      'de',
      'pl',
      'tr',
    ];

    return (
      <AsyncLanguageSwitcher
        className={cls(styles.languageSwitcherContainer, {
          [styles['languageSwitcherContainer--noMargin']]: partner && partner.disableProfile,
        })}
        dropdownClassName={styles.languageSwitcherDropdown}
        getUrlForSwitchLocale={this.getUrlForSwitchLocale}
        viewLocales={viewLocales}
        {...props}
      />
    );
  }

  handleCloseCustomPopup = () => {
    const { dispatch } = this.props;

    dispatch(closePopup());
  };

  checkIsGuestMode() {
    const {
      auth: { data },
    } = this.props;

    // eslint-disable-next-line no-prototype-builtins
    if (!data || !data.hasOwnProperty('auth')) {
      return null;
    }

    return _get(data, 'userStatus.guestMode', false);
  }

  renderItems() {
    const { config, product, pageInfo, location, webView } = this.props;

    if (!config || !config.items) {
      return null;
    }

    config.product = product;

    return config.items.map((item) => {
      const Comp = componentsMap[item.component];

      if (!Comp) {
        console.error(`There's no key ${item.component} in componentsMap!`);
        return null;
      }

      return (
        <Comp
          key={item.key}
          id={item.key}
          className={cls(styles.block, {
            [styles.hideOnMobile]: item.hideOnMobile,
          })}
          config={config}
          pageInfo={pageInfo}
          location={location}
          webView={webView}
          {...item.props}
        />
      );
    });
  }

  renderCookiePolicy() {
    const { webView } = this.props;
    const { showCookiePolicy } = this.state;

    if (webView.isWebView || !showCookiePolicy) {
      return null;
    }

    return <AsyncCookiePolicy />;
  }

  renderDownloadAppBanner() {
    const { auth, webView, config } = this.props;
    const { showDownloadAppBanner } = this.state;

    if (webView.isWebView || !showDownloadAppBanner) {
      return null;
    }

    if (config.partner) {
      return null;
    }

    return <DownloadAppBanner auth={auth} />;
  }

  renderCustomPopup() {
    const { customPopup } = this.props;

    if (!customPopup.isOpen) {
      return null;
    }

    const { data = {} } = customPopup;

    const Popup = customPopups[data.name];

    if (!Popup) {
      return null;
    }

    return <Popup {...data} isOpen={true} onClose={this.handleCloseCustomPopup} />;
  }

  renderFooterLanguageSwitcher() {
    const {
      config: { viewLocales, partner },
    } = this.props;

    if (partner && partner.disableLanguageSwitcher) {
      return null;
    }

    return this.getLanguageSwitcher({
      viewLocales,
      dropdownPosition: languageSwitcherHelpers.constants.DROPDOWN_POSITION.top,
    });
  }

  renderHeader() {
    const {
      config,
      config: { viewLocales },
      auth,
      authModal,
      webView,
    } = this.props;

    if (webView.isWebView) {
      return null;
    }

    const headerLanguageSwitcher = this.getLanguageSwitcher({
      showOnlyFlag: true,
      viewLocales,
      dropdownAlign: languageSwitcherHelpers.constants.DROPDOWN_ALIGN.right,
    });

    const mobileHeaderLanguageSwitcher = headerLanguageSwitcher;
    const isGuestMode = this.checkIsGuestMode();

    return (
      <>
        <IndexHeader
          auth={auth}
          authModal={authModal}
          languageSwitcher={headerLanguageSwitcher}
          config={config}
          className={styles.header}
          isGuestMode={isGuestMode}
        />
        <MobileIndexHeader
          auth={auth}
          authModal={authModal}
          languageSwitcher={mobileHeaderLanguageSwitcher}
          config={config}
          className={styles.mobileHeader}
        />
      </>
    );
  }

  renderFooter() {
    const { config, webView } = this.props;

    if (webView.isWebView) {
      return null;
    }

    const footerLanguageSwitcher = this.renderFooterLanguageSwitcher();

    return (
      <IndexFooter
        className={styles.footer}
        config={config}
        languageSwitcher={footerLanguageSwitcher}
      />
    );
  }

  render() {
    const {
      className,
      auth: { data: authData },
      pageInfo: {
        deviceInfo: { isMobile },
      },
      config,
    } = this.props;

    const isAuth = authData && authData.auth;

    return (
      <>
        <Helmet>
          <title>{config.meta.title}</title>
        </Helmet>
        <div className={cls(styles.container, className)}>
          <ErrorBoundary>
            <div className={styles.content}>
              {this.renderCookiePolicy()}
              {this.renderDownloadAppBanner()}
              <div>
                <AsyncReservationBanner isMobile={isMobile} isAuth={isAuth} product="index" />
              </div>
              {this.renderHeader()}
              {this.renderItems()}
            </div>
            {this.renderFooter()}
            {this.renderCustomPopup()}
          </ErrorBoundary>
        </div>
      </>
    );
  }
}

export default withRouter(IndexPage);

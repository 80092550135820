export const PAYMENT_METHODS = {
  WIDGET: 'widget',
  APPLE_PAY: 'apple_pay',
  BANK_CARD: 'bank_card',
};

export const KNOWN_PAYMENT_METHODS = Object.values(PAYMENT_METHODS);
export const PAYMENT_METHODS_ORDER = [
  PAYMENT_METHODS.BANK_CARD,
  PAYMENT_METHODS.APPLE_PAY,
  PAYMENT_METHODS.WIDGET,
];

/**
 * Информация по статусу доплаты.
 *
 * waiting - ожидает оплаты;
 * block - деньги заблокированы;
 * 3ds - ожидает подтверждения от ACS;
 * success - оплата прошла успешно;
 * failBlock - блокировка НЕ прошла(можно повторить);
 * fail - оплата НЕ прошла(средства остались заблокированы);
 *
 * Статус неудачи не означает ошибку запроса. Каждый из этих статусов
 * приходит с кодом 200 на запросы /status и /link
 */

export const SURCHARGE_STATUSES = {
  WAITING: 'waiting',
  BLOCK: 'block',
  FAIL_BLOCK: 'failBlock',
  FAIL: 'fail',
  SUCCESS: 'success',
  THREE_DS: '3ds',
  BRIDGER: 'bridger',
};

/**
 * Ошибки от бэка. Отмененный заказ доплатить нельзя,
 * поэтому ввели такой код, чтобы можно было определить причину
 * неудачи запроса /pay
 */

export const API_ERRORS = {
  ORDER_CANCELLED: 'orderCancelled',
};

export const CANNOT_PAY_STATUSES = [SURCHARGE_STATUSES.BLOCK, SURCHARGE_STATUSES.FAIL];

export const OLD_3DS = 1;

/**
  Коды ошибок на фронте
  По ним мы определяем что рендерить
 */

export const ERRORS = {
  NETWORK: 'network',
  CANNOT_PAY: 'cannot_pay',
  PAID_EARLY: 'paid_early',
  EXPIRED: 'expired',
  FAIL_ON_PAY: 'fail_on_pay',
};

export const THREE_DS_RETURN_QUERY_PARAM = 'returned_from_acs';
export const SAVED_STATE_LS_KEY = 'stateSavedBeforeGoingToACS';

export const PRODUCTS = {
  HOTELS: 'hotels',
  AVIA: 'avia',
};

export const KNOWN_PRODUCTS = Object.values(PRODUCTS);

export const moneyFormatOptions = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
};

export const SKELETON_TIME = 1200;
